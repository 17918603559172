<template>
  <div class="agent-utilization">
    <p class="title">{{ __("At a glance") }}</p>
    <div class="card-contents">
      <div class="actions">
        <template v-if="utilizationState === 'danger'">
          <div>{{ __("WARNING") }}:</div>
          <div>{{ __("Risk of dropped calls") }}</div>
        </template>
        <template v-if="utilizationState === 'warning'">
          <div>{{ __("Approaching limit") }}</div>
          <!--          <div class="register-warning-action">-->
          <!--            Register for usage warning notifications-->
          <!--          </div>-->
        </template>
      </div>

      <div class="utilization-data">
        <div class="utilization-percentage">{{ animatedUtilization }}%</div>
        <div class="info-label">{{ __("Virtual Agent Utilization") }}</div>
      </div>
      <el-button
        v-if="can('analyse.live-virtual-agent-tracking')"
        @click="routeToVirtualAgentTrackerPage"
        class="view-usage-button"
        :class="utilizationState"
        >{{ __("Live usage") }}</el-button
      >

      <el-image
        class="dashboard-card"
        :src="utilizationCard"
        alt="image1"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import gsap from "gsap";

export default {
  name: "AgentUtilizationCard",
  data() {
    return {
      tweenedUtilization: 0
    };
  },
  computed: {
    ...mapState("app", {
      virtualAgentUsage: state => state.virtualAgentUsage,
      selectedAccountId: state => state.selectedAccountId,
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    animatedUtilization: function() {
      return !this.tweenedUtilization
        ? parseInt("0").toFixed(0)
        : (+this.tweenedUtilization).toFixed(0);
    },

    utilization() {
      return _.get(this.virtualAgentUsage, this.selectedAccountId);
    },
    utilizationCard() {
      return require("@/assets/images/agent-utilization/agent-utilization-" +
        this.utilizationState +
        ".svg");
    },
    utilizationState() {
      if (this.utilization < 70) {
        return "normal";
      } else if (this.utilization < 95) {
        return "warning";
      } else {
        return "danger";
      }
    }
  },
  methods: {
    routeToVirtualAgentTrackerPage() {
      this.$router
        .push({
          name: "live-virtual-agent-tracker",
          params: {
            ac_id: this.selectedAccountId,
            sp_id: this.selectedServiceProviderId
          }
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.tweenedUtilization = this.utilization;
  },
  watch: {
    utilization(val) {
      gsap.to(this.$data, { duration: 0.5, tweenedUtilization: val });
    }
  }
};
</script>

<style scoped lang="scss">
.agent-utilization {
  position: relative;

  .title {
    position: absolute;
    top: -50px;
    left: 0;
    color: #181f29;
  }
  .card-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 8px 3px rgba(168, 168, 168, 0.3);
    max-width: 225px;

    @media only screen and (min-width: 375px) {
      max-width: 285px;
    }

    @media only screen and (min-width: 425px) {
      max-width: 335px;
    }

    @media only screen and (min-width: 768px) {
      max-width: 350px;
    }

    @media only screen and (min-width: 992px) {
      max-width: 380px;
    }

    @media only screen and (min-width: 1200px) {
      max-width: 500px;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 600px;
    }

    .utilization-data {
      position: absolute;
      left: 6%;
      color: white;
      padding-bottom: 20px;
      z-index: -1;

      .utilization-percentage {
        font-size: 1.4rem;
        font-weight: 900;

        @media only screen and (min-width: 425px) {
          font-size: 1.6rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 1.5rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 1.8rem;
        }

        @media only screen and (min-width: 1200px) {
          font-size: 1.9rem;
        }

        @media only screen and (min-width: 1920px) {
          font-size: 2.5rem;
        }
      }

      .info-label {
        font-size: 1rem;
        font-weight: 700;

        @media only screen and (min-width: 425px) {
          font-size: 1.2rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 1.1rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 1.2rem;
        }

        @media only screen and (min-width: 1200px) {
          font-size: 1.3rem;
        }

        @media only screen and (min-width: 1920px) {
          font-size: 1.5rem;
        }
      }
    }

    .actions {
      position: absolute;
      top: 10%;
      right: 5%;
      color: rgba(255, 255, 255, 0.4);
      font-weight: 600;
      font-size: 0.6125rem;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .register-warning-action {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: rgba(255, 255, 255, 0.4);

        &:hover {
          color: rgba(255, 255, 255, 0.9);
          text-decoration-color: rgba(255, 255, 255, 0.9);
        }
      }

      @media only screen and (min-width: 375px) {
        font-size: 0.6725rem;
      }

      @media only screen and (min-width: 425px) {
        font-size: 0.7225rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 0.7125rem;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 0.8125rem;
      }

      @media only screen and (min-width: 1920px) {
        font-size: 1rem;
      }
    }

    .view-usage-button {
      position: absolute;
      bottom: 5%;
      left: 6%;

      padding: 8px 15px;
      border-radius: 50px;
      border: none;
      color: white;
      font-weight: 600;
      font-size: 0.6125rem;

      @media only screen and (min-width: 425px) {
        font-size: 0.7225rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 0.7125rem;
      }

      @media only screen and (min-width: 1200px) {
        padding: 10px 20px;
      }

      @media only screen and (min-width: 1920px) {
        font-size: 0.9125rem;
      }

      &.normal {
        background-color: #2285d8;
        &:hover {
          background-color: darken(#2285d8, 5%);
        }
      }

      &.warning {
        background-color: goldenrod;
        &:hover {
          background-color: darken(goldenrod, 5%);
        }
      }

      &.danger {
        background-color: firebrick;
        &:hover {
          background-color: darken(firebrick, 5%);
        }
      }
    }

    .dashboard-card {
      z-index: -2;
    }
  }
}
</style>

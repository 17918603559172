<template>
  <div
    style="margin-bottom: 20px;"
    class="secondary-title"
    v-if="recentTasks.length > 0"
  >
    {{ __("Recent Tasks") }}
    <el-table ref="recentTaskTable" class="list-table" :data="recentTasks">
      <el-table-column prop="task_name">
        <template slot-scope="scope">
          <span
            class="link-span"
            @click="openCallFlowEditor(scope.row.task_id)"
            >{{ scope.row.task_name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="event_time">
        <template slot-scope="scope">
          {{ __("Edited ") + scope.row.event_time }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    },
    numberOfTasks: {
      required: false,
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      fetchedRecentTasks: false,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 5
      }
    };
  },
  computed: {
    ...mapState("tasks", {
      recentTasks: state => state.tasks
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userApplicationViewScope: state => state.userApplicationViewScope
    })
  },
  async created() {
    await this.initializeContents();
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },
  methods: {
    ...mapActions("tasks", {
      getTasks: "getTasks"
    }),

    openCallFlowEditor(task_id) {
      this.$router.push({
        name: "callflow",
        params: { task_id: task_id }
      });
    },

    async initializeContents() {
      this.fetchedRecentTasks = false;
      if (!this.msg) {
        await this.getTasks({
          per_page: 5,
          page: 1,
          orderBy: "updated_at",
          sortedBy: "desc"
        });
      }

      this.fetchedRecentTasks = true;
    }
  },
  watch: {
    selectedServiceProviderId: "initializeContents",
    selectedAccountId: "initializeContents"
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/element-variables.scss";
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.secondary-title {
  font-weight: 500;
  color: #181f29;
}
.link-span {
  cursor: pointer;
}
</style>

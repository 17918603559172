<template>
  <div ref="banner" :class="'banner ' + getBannerColorClass" v-if="showBanner">
    <div class="iconAndContent">
      <img ref="icon" :src="getIconSvg" class="icon" onload="SVGInject(this)" />
      <div class="titleAndContent">
        <div ref="title" :class="'title ' + getBannerTextColorClass">
          {{ bannerTitle }}
        </div>
        <div
          ref="content"
          :class="'content' + getBannerTextColorClass"
          v-if="!usingContentSlot"
        >
          {{ bannerContent }}
        </div>
        <div ref="extraInfoSlot" v-else>
          <slot name="extraInfo" :handleExtraInfo="handleExtraInfo" />
        </div>
      </div>
    </div>
    <div ref="closeButton" class="closeButton" @click="handleCloseBannerButton">
      <img :src="getCloseSvg" class="closeIcon" onload="SVGInject(this)" />
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerComponent",

  props: {
    bannerType: {
      required: false,
      default: "info"
    },

    bannerTitle: {
      required: true
    },
    bannerContent: {
      required: false
    },
    usingContentSlot: {
      default: false,
      required: false
    }
  },
  data() {
    return {
      bannerIsShowing: true
    };
  },

  methods: {
    handleExtraInfo() {
      this.$emit("handle-extra-info");
    },
    handleCloseBannerButton() {
      this.bannerIsShowing = false;
    }
  },

  computed: {
    getBannerColorClass() {
      return this.bannerType + "-banner-color";
    },

    getBannerTextColorClass() {
      return this.bannerType + "-color";
    },

    showBanner() {
      return this.bannerIsShowing;
    },
    getCloseSvg() {
      return require("@/assets/icons/icon-close.svg");
    },
    getIconSvg() {
      if (this.bannerType == "warning") {
        return require("@/assets/icons/icon-warning-triangle.svg");
      } else {
        return require("@/assets/icons/icon-info.svg");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  width: 757px;
  height: 53px;
  padding: 16px;
  align-items: center;
  display: flex;
  border-radius: 4px;
}

.iconAndContent {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.titleAndContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.title {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.content {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.banner.info.content {
  color: #4d91ff;
}

.banner.warning.content {
  color: #d8181b;
}

.closeButton {
  display: flex;
  padding: 5.5px;
  align-items: flex-start;
  gap: 108px;
  cursor: pointer;
}
.icon {
  height: 32px;
  width: 32px;
}

.closeIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.extraInfo {
  color: #244cde;
  cursor: pointer;
}

.closeButton:hover {
  background-color: darken(#feecec, 2%);
}

.info-banner-color {
  background: #e5efff;
}

.warning-banner-color {
  background: #feecec;
}

.info-color {
  color: #4d91ff;
}

.warning-color {
  color: #d8181b;
}
</style>

<template>
  <div class="utilization">
    <div class="card-contents">
      <div class="utilization-data">
        <div class="utilization-percentage">
          {{ this.utilization.usagePercentage }}%
        </div>
        <div class="info-label">{{ __("Chatbot Messages Used") }}</div>
        <div style="display: flex">
          <div style="padding-top: 15px">
            {{ this.utilization.usagesLeft }}{{ __(" Messages Left") }}
          </div>
          <div
            style="padding-top: 15px; padding-left: 20px"
            v-if="this.utilization.featureBurstLimit > 0"
          >
            {{ this.utilization.burstUsagesLeft
            }}{{ __(" Burst Messages Left") }}
          </div>
        </div>
        <div style="padding-top: 15px; font-size: 13px">
          Note: Data displayed is updated every 15 minutes
        </div>
      </div>

      <el-image
        class="dashboard-card"
        :src="utilizationCard"
        alt="image1"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  name: "AgentUtilizationCard",
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      chatbotUsage: state => state.chatbotUsage,
      selectedAccountId: state => state.selectedAccountId,
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    utilizationCard() {
      return require("@/assets/images/agent-utilization/agent-utilization-" +
        this.utilizationState +
        ".svg");
    },
    utilization() {
      return _.get(this.chatbotUsage, this.selectedAccountId);
    },
    utilizationState() {
      if (this.utilization.usagePercentage < 70) {
        return "normal";
      } else if (this.utilization.usagePercentage < 95) {
        return "warning";
      } else {
        return "danger";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.utilization {
  position: relative;

  .title {
    position: absolute;
    top: -50px;
    left: 0;
    color: #181f29;
  }
  .card-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 8px 3px rgba(168, 168, 168, 0.3);
    max-width: 225px;

    @media only screen and (min-width: 375px) {
      max-width: 285px;
    }

    @media only screen and (min-width: 425px) {
      max-width: 335px;
    }

    @media only screen and (min-width: 768px) {
      max-width: 350px;
    }

    @media only screen and (min-width: 992px) {
      max-width: 380px;
    }

    @media only screen and (min-width: 1200px) {
      max-width: 500px;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 600px;
    }

    .utilization-data {
      position: absolute;
      left: 6%;
      color: white;
      padding-bottom: 20px;
      z-index: -1;

      .utilization-percentage {
        font-size: 1.4rem;
        font-weight: 900;

        @media only screen and (min-width: 425px) {
          font-size: 1.6rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 1.5rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 1.8rem;
        }

        @media only screen and (min-width: 1200px) {
          font-size: 1.9rem;
        }

        @media only screen and (min-width: 1920px) {
          font-size: 2.5rem;
        }
      }

      .info-label {
        font-size: 1rem;
        font-weight: 700;

        @media only screen and (min-width: 425px) {
          font-size: 1.2rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 1.1rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 1.2rem;
        }

        @media only screen and (min-width: 1200px) {
          font-size: 1.3rem;
        }

        @media only screen and (min-width: 1920px) {
          font-size: 1.5rem;
        }
      }
    }

    .dashboard-card {
      z-index: -2;
    }
  }
}
</style>

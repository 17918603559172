<template>
  <div class="component-container">
    <page-header :title="__('Dashboard')" />
    <!-- CHATBOT BURST USAGE WARNING BANNER -->
    <banner
      ref="chatbotBanner"
      v-if="chatbotUtilization.isUsingBurstUsages"
      :banner-title="__('ChatBot Balance Depleted')"
      :using-content-slot="true"
      :banner-type="'warning'"
      style="margin-bottom: 30px"
      @handle-extra-info="routeToVirtualAgentUsagePage"
    >
      <template #extraInfo="extraInfo">
        <div ref="extraInfoButton" @click="extraInfo.handleExtraInfo">
          <div style="display:flex" class="content warning-color">
            {{ __("Your account is currently using Burst Messages.") }}
            <div>&nbsp;</div>
            <div class="extraInfo">{{ __("Review Usage") }}</div>
          </div>
        </div>
      </template>
    </banner>
    <el-row class="dashboard-first-row">
      <el-col :sm="10" :md="12">
        <template>
          <el-carousel
            trigger="click"
            height="300px"
            :autoplay="false"
            :interval="5000"
          >
            <el-carousel-item>
              <div style="display: flex; justify-content:center">
                <el-col :sm="10" :md="17">
                  <agent-utilization-card />
                </el-col>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div style="display: flex; justify-content:center">
                <el-col :sm="10" :md="17"> <call-volume-card /> </el-col>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div style="display: flex; justify-content:center">
                <el-col :sm="14" :md="17"><chatbot-usage-card /></el-col>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
      </el-col>
      <el-col class="hidden-sm-and-down" :md="7">
        <announcements class="announcements-col" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <recent-task-table />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <activity-log-table />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import AgentUtilizationCard from "@/views/dashboard/components/AgentUtilizationCard";
import ChatbotUsageCard from "@/views/dashboard/components/ChatbotUsageCard";
import CallVolumeCard from "@/views/dashboard/components/CallVolumeCard";
import Announcements from "@/views/dashboard/components/Announcements";
import ActivityLogTable from "@/views/dashboard/components/ActivityLogTable";
import Banner from "@/components/Banner";
import _ from "lodash";
import { mapState } from "vuex";
import RecentTaskTable from "@/views/dashboard/components/RecentTaskTable";

export default {
  name: "AccountDashboardIndex",
  components: {
    ActivityLogTable,
    Announcements,
    CallVolumeCard,
    AgentUtilizationCard,
    PageHeader,
    ChatbotUsageCard,
    Banner,
    RecentTaskTable
  },

  methods: {
    routeToVirtualAgentUsagePage() {
      this.$router.push({ name: "virtual-agent-usage" }).catch(() => {});
    }
  },

  computed: {
    ...mapState("app", {
      chatbotUsage: state => state.chatbotUsage,
      selectedAccountId: state => state.selectedAccountId,
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    chatbotUtilization() {
      return _.get(this.chatbotUsage, this.selectedAccountId);
    }
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-left: 0;
}

.announcements-col {
  padding-left: 40px;
}
</style>

<template>
  <div>
    <div v-if="!isAllServiceProvidersSelected" class="dashboard-container">
      <component :is="currentScope" />
    </div>
    <div v-else>
      <not-scoped resource="Dashboard" scope="Service Provider" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import accountDashboard from "./account";
import serviceProviderDashboard from "./serviceprovider";
import NotScoped from "@/components/NotScoped";
export default {
  name: "DashboardIndex",
  data() {
    return {
      currentScope: null
    };
  },

  components: {
    NotScoped,
    accountDashboard,
    serviceProviderDashboard
  },

  computed: {
    ...mapState("app", {
      userApplicationViewScope: state => state.userApplicationViewScope,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userEmail: state => state.userEmail
    }),
    isAllServiceProvidersSelected() {
      return "all" === this.selectedServiceProviderId;
    }
  },

  methods: {
    ...mapActions("app", {
      setRedirected: "setRedirected"
    }),
    initializeDashboard() {
      this.currentScope =
        this.userApplicationViewScope !== "ac"
          ? "serviceProviderDashboard"
          : "accountDashboard";
    }
  },
  mounted() {
    this.setRedirected(false);
  },
  watch: {
    userApplicationViewScope: {
      immediate: true,
      handler: "initializeDashboard"
    }
  }
};
</script>

<style lang="scss">
.dashboard-first-row {
  padding-top: 25px;
  margin-bottom: 50px;
  margin-left: -30px;
  margin-top: -20px;
  > * {
    margin-top: 20px;
    margin-left: 30px;
  }
}
</style>

<style lang="scss" scoped></style>

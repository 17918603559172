<template>
  <el-row class="virtual-agent-usage-row" type="flex">
    <el-col :span="10" class="account-name"> {{ account.ac_name }}</el-col>
    <el-col class="virtual-agent-usage" :class="getClass" :span="7"
      >{{ animatedUtilization }}%</el-col
    >
    <el-col :span="7" class="btn-col">
      <el-button
        v-if="can('analyse.live-virtual-agent-tracking')"
        @click="routeToVirtualAgentTrackerPage"
        class="view-usage-btn"
        >{{ __("Live usage") }}</el-button
      >
    </el-col>
  </el-row>
</template>

<script>
import gsap from "gsap";
import { mapState } from "vuex";

export default {
  name: "TopAccount",
  props: {
    account: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tweenedUtilization: 0
    };
  },
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),
    animatedUtilization: function() {
      return !this.tweenedUtilization
        ? parseInt("0").toFixed(0)
        : this.tweenedUtilization.toFixed(0);
    },

    getClass() {
      if (this.tweenedUtilization < 70) {
        return "normal";
      } else if (this.tweenedUtilization < 95) {
        return "warning";
      } else {
        return "danger";
      }
    }
  },
  methods: {
    routeToVirtualAgentTrackerPage() {
      this.$router
        .push({
          name: "live-virtual-agent-tracker",
          params: {
            ac_id: this.account.ac_id,
            sp_id: this.selectedServiceProviderId,
            returnToAll: true,
            from_ac_id: "all"
          }
        })
        .catch(() => {});
    }
  },
  mounted() {
    this.tweenedUtilization = this.account.usage;
  },
  watch: {
    "account.usage": {
      immediate: true,
      handler(val) {
        gsap.to(this.$data, { duration: 0.5, tweenedUtilization: val });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.virtual-agent-usage-row {
  align-items: baseline;

  .account-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-size: 0.75rem;

    @media only screen and (min-width: 375px) {
      font-size: 0.8rem;
    }

    @media only screen and (min-width: 768px) {
      font-size: 0.9rem;
    }

    @media only screen and (min-width: 1200px) {
      font-size: 0.95rem;
    }

    @media only screen and (min-width: 1440px) {
      font-size: 1rem;
    }

    @media only screen and (min-width: 2560px) {
      font-size: 1.2rem;
    }
  }
  .virtual-agent-usage {
    text-align: center;
    transition: 0.5s;
    font-weight: 900;

    font-size: 0.95rem;
    transform: scale(1.4, 1.4);

    @media only screen and (min-width: 375px) {
      font-size: 1.1rem;
    }

    @media only screen and (min-width: 425px) {
      font-size: 1.3rem;
    }

    @media only screen and (min-width: 992px) {
      font-size: 1.2rem;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 1.3rem;
    }

    @media only screen and (min-width: 1200px) {
      font-size: 1.4rem;
    }

    @media only screen and (min-width: 2560px) {
      font-size: 2rem;
    }

    &.normal {
      color: #2285d8;
    }

    &.warning {
      color: goldenrod;
    }

    &.danger {
      color: firebrick;
    }
  }

  .btn-col {
    align-self: center;
    display: flex;
    justify-content: flex-end;

    @media only screen and (min-width: 992px) {
      justify-content: center;
    }
    .view-usage-btn {
      background-color: #666666;
      border-radius: 50px;
      border: none;
      color: white;
      font-weight: 400;

      padding: 4px 6px;
      font-size: 0.6125rem;

      @media only screen and (min-width: 375px) {
        padding: 5px 7px;
        font-size: 0.7rem;
      }

      @media only screen and (min-width: 425px) {
        padding: 6px 10px;
        font-size: 0.75rem;
      }

      @media only screen and (min-width: 992px) {
        padding: 6px 8px;
        font-size: 0.7rem;
      }

      @media only screen and (min-width: 1440px) {
        padding: 7px 12px;
        font-size: 0.775rem;
      }

      @media only screen and (min-width: 1920px) {
        padding: 8px 15px;
        font-size: 0.825rem;
      }

      @media only screen and (min-width: 2560px) {
        padding: 10px 20px;
        font-size: 1rem;
      }

      &:hover {
        background-color: darken(#666666, 10%);
      }
    }
  }
}
</style>

<template>
  <div>
    <div style="margin-bottom: 20px;" class="secondary-title">
      {{ __("Recent activity") }}
    </div>
    <el-table ref="activityLogTable" class="list-table" :data="activityLogs">
      <el-table-column :label="__('Event Description')" prop="description">
        <template slot-scope="scope">
          <div v-if="scope.row.event_url">
            <el-row
              type="flex"
              justify="flex-start"
              style="align-items: center"
            >
              <span>{{ scope.row.description }}</span>
            </el-row>
          </div>
          <div v-else>
            <span>{{ scope.row.description }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="__('Date')" prop="event_time" />
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    },

    numberOfLogs: {
      required: false,
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      fetchedActivityLogs: false,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("activitylogs", {
      activityLogs: state => state.activityLogs
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userApplicationViewScope: state => state.userApplicationViewScope
    }),

    activityLogsToShow() {
      return _.groupBy(this.activityLogs, function(log) {
        return log.date;
      });
    }
  },

  async created() {
    this.initializeContents();
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },

  methods: {
    ...mapActions("activitylogs", {
      getActivityLogs: "getActivityLogs"
    }),

    async initializeContents() {
      this.fetchedActivityLogs = false;
      if (!this.msg) {
        await this.getActivityLogs({
          per_page: 5,
          page: 1,
          orderBy: "id",
          sortedBy: "desc"
        });
      }

      this.fetchedActivityLogs = true;
    }
  },

  watch: {
    selectedServiceProviderId: "initializeContents",
    selectedAccountId: "initializeContents"
  }
};
</script>
<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/element-variables.scss";
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
.secondary-title {
  font-weight: 500;
  color: #181f29;
}
</style>

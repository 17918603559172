<template>
  <div class="chatbot-utilization">
    <div class="card-contents" v-if="!isLoading">
      <div class="utilization-data">
        <div class="title">
          {{ __("Chatbot Usage - Top Accounts") }}
        </div>
        <div class="top-accounts">
          <transition-group name="list">
            <div
              v-for="account in top3AccountsByChatbotUsage"
              :key="account.ac_id"
            >
              <template>
                <el-row class="chatbot-usage-row" type="flex">
                  <el-col :span="8" class="account-name">
                    {{ account.ac_name }}</el-col
                  >
                  <el-col
                    class="chatbot-usage"
                    :class="getClass(account.usage.usagePercentage)"
                    :span="7"
                    >{{ account.usage.usagePercentage }}%</el-col
                  >
                  <el-col
                    style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"
                    :span="9"
                  >
                    <div style="display: flex; flex-direction: column">
                      <div>{{ account.usage.usagesLeft }} Messages Left</div>
                      <div v-if="account.usage.featureBurstLimit > 0">
                        {{ account.usage.burstUsagesLeft }} Burst Messages Left
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </div>
          </transition-group>
        </div>
      </div>

      <el-image
        class="dashboard-card"
        :src="utilizationCard"
        alt="image1"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TopVirtualAgentUtilizersCard",
  data() {
    return {};
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapGetters("app", {
      top3AccountsByVirtualAgentUsage: "top3AccountsByVirtualAgentUsage",
      top3AccountsByChatbotUsage: "top3AccountsByChatbotUsage"
    }),

    utilizationCard() {
      return require(`@/assets/images/agent-utilization/agent-utilization-top-accounts.svg`);
    },
    getClass() {
      return percentage => {
        if (percentage < 70) {
          return "normal";
        } else if (percentage < 95) {
          return "warning";
        } else {
          return "danger";
        }
      };
    }
  }
};
</script>

<style scoped lang="scss">
.chatbot-utilization {
  position: relative;

  > .title {
    position: absolute;
    top: -50px;
    left: 0;
    color: #181f29;
  }

  .card-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 8px 3px rgba(168, 168, 168, 0.3);
    max-width: 225px;

    @media only screen and (min-width: 375px) {
      max-width: 285px;
    }

    @media only screen and (min-width: 425px) {
      max-width: 335px;
    }

    @media only screen and (min-width: 768px) {
      max-width: 350px;
    }

    @media only screen and (min-width: 992px) {
      max-width: 380px;
    }

    @media only screen and (min-width: 1200px) {
      max-width: 500px;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 600px;
    }

    .utilization-data {
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: space-around;

      .title {
        align-self: center;
        padding-top: 5px;
        font-size: 0.7rem;

        @media only screen and (min-width: 375px) {
          font-size: 0.8rem;
        }

        @media only screen and (min-width: 425px) {
          font-size: 0.875rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 0.9rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 0.875rem;
        }

        @media only screen and (min-width: 1440px) {
          font-size: 0.9rem;
        }

        @media only screen and (min-width: 1920px) {
          font-size: 1rem;
        }

        @media only screen and (min-width: 2560px) {
          font-size: 1.4rem;
        }
      }

      .top-accounts {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 5px 10px;

        @media only screen and (min-width: 425px) {
          padding: 5px 15px;
        }

        @media only screen and (min-width: 992px) {
          padding: 5px 25px;
        }

        > span {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .chatbot-usage {
          text-align: center;
        }

        .list-leave-active {
          position: absolute;
        }

        .list-enter-active {
          transition: all 0.75s;
        }

        .list-enter,
        .list-leave-to {
          opacity: 0;
        }

        .list-move {
          transition: transform 0.5s;
        }
      }
    }

    .dashboard-card {
      z-index: -2;
    }
  }
}
.chatbot-usage-row {
  align-items: baseline;

  .account-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    font-size: 0.75rem;

    @media only screen and (min-width: 375px) {
      font-size: 0.8rem;
    }

    @media only screen and (min-width: 768px) {
      font-size: 0.9rem;
    }

    @media only screen and (min-width: 1200px) {
      font-size: 0.95rem;
    }

    @media only screen and (min-width: 1440px) {
      font-size: 1rem;
    }

    @media only screen and (min-width: 2560px) {
      font-size: 1.2rem;
    }
  }
  .chatbot-usage {
    text-align: center;
    transition: 0.5s;
    font-weight: 900;

    font-size: 0.95rem;
    transform: scale(1.4, 1.4);

    @media only screen and (min-width: 375px) {
      font-size: 1.1rem;
    }

    @media only screen and (min-width: 425px) {
      font-size: 1.3rem;
    }

    @media only screen and (min-width: 992px) {
      font-size: 1.2rem;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 1.3rem;
    }

    @media only screen and (min-width: 1200px) {
      font-size: 1.2rem;
    }

    @media only screen and (min-width: 2560px) {
      font-size: 1.6rem;
    }

    &.normal {
      color: #2285d8;
    }

    &.warning {
      color: goldenrod;
    }

    &.danger {
      color: firebrick;
    }
  }
}
</style>

<template>
  <div>
    <div
      class="announcement-group"
      :key="index"
      v-for="(items, type, index) in announcements"
    >
      <announcement-group :group="type" :items="items" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AnnouncementGroup from "@/views/dashboard/components/AnnouncementGroup";
export default {
  name: "AnnouncementsComponent",
  components: { AnnouncementGroup },
  computed: {
    ...mapState("app", {
      announcements: state => state.announcements
    })
  }
};
</script>

<style scoped lang="scss">
.announcement-group:not(:first-child) {
  margin-top: 40px;
}

.announcement-group:first-child {
  margin-top: -34px;
}
</style>

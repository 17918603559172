<template>
  <div class="group-container">
    <div class="group-name">{{ getLabel(group) }}</div>
    <div v-if="items.length">
      <div class="group-items">
        <transition-group name="list">
          <a
            :key="item.announcement_id"
            v-for="item in items"
            :href="item.url"
            target="_blank"
          >
            {{ item.label }}
          </a>
        </transition-group>
      </div>
    </div>
    <div class="empty-items" v-else>{{ __("Nothing new") }}</div>
  </div>
</template>

<script>
import { typeToLabel } from "@/constants/dashboard";

export default {
  name: "AnnouncementGroup",
  props: {
    group: {
      required: true,
      type: String
    },
    items: {
      required: false,
      type: Array,
      default: () => []
    }
  },
  computed: {
    getLabel() {
      return type => typeToLabel[type] || type;
    }
  }
};
</script>

<style scoped lang="scss">
.group-container {
  .group-name {
    font-weight: 500;
    font-size: 1rem;

    @media only screen and (min-width: 1440px) {
      font-size: 1.05rem;
    }
    @media only screen and (min-width: 1920px) {
      font-size: 1.1rem;
    }
  }

  .empty-items {
    font-size: 0.75rem;
    padding-top: 10px;

    @media only screen and (min-width: 1200px) {
      padding-top: 12px;
      font-size: 0.775rem;
    }

    @media only screen and (min-width: 1440px) {
      padding-top: 15px;
      font-size: 0.85rem;
    }

    @media only screen and (min-width: 1920px) {
      padding-top: 18px;
      font-size: 0.9rem;
    }

    @media only screen and (min-width: 2560px) {
      padding-top: 25px;
      font-size: 1rem;
    }
  }

  .group-items {
    > span {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-height: 80px;

      a {
        padding-top: 10px;
        text-decoration: none;
        color: #2285d8;
        font-size: 0.75rem;
        word-break: break-word;

        @media only screen and (min-width: 1200px) {
          padding-top: 12px;
          font-size: 0.775rem;
        }

        @media only screen and (min-width: 1440px) {
          padding-top: 15px;
          font-size: 0.85rem;
        }

        @media only screen and (min-width: 1920px) {
          padding-top: 18px;
          font-size: 0.9rem;
        }

        @media only screen and (min-width: 2560px) {
          padding-top: 25px;
          font-size: 1rem;
        }

        &:hover {
          color: darken(#2285d8, 10%);
        }
      }
    }

    .list-leave-active {
      position: absolute;
    }

    .list-enter-active {
      transition: all 0.75s;
    }

    .list-enter,
    .list-leave-to {
      opacity: 0;
    }

    .list-move {
      transition: transform 0.5s;
    }
  }
}
</style>

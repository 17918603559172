<template>
  <div class="agent-utilization">
    <p class="title">{{ __("At a glance") }}</p>

    <div class="card-contents">
      <div class="utilization-data">
        <!--        <div class="utilization-percentage">{{ animatedUtilization }}%</div>-->
        <!--        <div class="info-label">Virtual Agent Utilization</div>-->
        <div class="title">
          {{ __("Virtual agent utilization - Top Accounts") }}
        </div>
        <div class="top-accounts">
          <transition-group name="list">
            <div
              v-for="account in top3AccountsByVirtualAgentUsage"
              :key="account.ac_id"
            >
              <top-account :account="account" />
            </div>
          </transition-group>
        </div>
      </div>

      <el-image
        class="dashboard-card"
        :src="utilizationCard"
        alt="image1"
      ></el-image>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TopAccount from "@/views/dashboard/components/TopAccount";

export default {
  name: "TopVirtualAgentUtilizersCard",
  components: { TopAccount },
  computed: {
    ...mapState("app", {
      virtualAgentUsage: state => state.virtualAgentUsage,
      selectedAccountId: state => state.selectedAccountId
    }),

    ...mapGetters("app", {
      top3AccountsByVirtualAgentUsage: "top3AccountsByVirtualAgentUsage"
    }),
    utilizationCard() {
      return require(`@/assets/images/agent-utilization/agent-utilization-top-accounts.svg`);
    }
  }
};
</script>

<style scoped lang="scss">
.agent-utilization {
  position: relative;

  > .title {
    position: absolute;
    top: -50px;
    left: 0;
    color: #181f29;
  }

  .card-contents {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    box-shadow: 0 0 8px 3px rgba(168, 168, 168, 0.3);
    max-width: 225px;

    @media only screen and (min-width: 375px) {
      max-width: 285px;
    }

    @media only screen and (min-width: 425px) {
      max-width: 335px;
    }

    @media only screen and (min-width: 768px) {
      max-width: 350px;
    }

    @media only screen and (min-width: 992px) {
      max-width: 380px;
    }

    @media only screen and (min-width: 1200px) {
      max-width: 500px;
    }

    @media only screen and (min-width: 1920px) {
      max-width: 600px;
    }

    .utilization-data {
      position: absolute;
      color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      justify-content: space-around;

      .title {
        align-self: center;
        padding-top: 5px;
        font-size: 0.7rem;

        @media only screen and (min-width: 375px) {
          font-size: 0.8rem;
        }

        @media only screen and (min-width: 425px) {
          font-size: 0.875rem;
        }

        @media only screen and (min-width: 768px) {
          font-size: 0.9rem;
        }

        @media only screen and (min-width: 1024px) {
          font-size: 0.875rem;
        }

        @media only screen and (min-width: 1440px) {
          font-size: 0.9rem;
        }

        @media only screen and (min-width: 1920px) {
          font-size: 1rem;
        }

        @media only screen and (min-width: 2560px) {
          font-size: 1.4rem;
        }
      }

      .top-accounts {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 5px 10px;

        @media only screen and (min-width: 425px) {
          padding: 5px 15px;
        }

        @media only screen and (min-width: 992px) {
          padding: 5px 25px;
        }

        > span {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .virtual-agent-usage {
          text-align: center;
        }

        .list-leave-active {
          position: absolute;
        }

        .list-enter-active {
          transition: all 0.75s;
        }

        .list-enter,
        .list-leave-to {
          opacity: 0;
        }

        .list-move {
          transition: transform 0.5s;
        }
      }
    }

    .dashboard-card {
      z-index: -2;
    }
  }
}
</style>

<template>
  <div class="component-container">
    <page-header :title="__('Dashboard')" />
    <el-row class="dashboard-first-row">
      <el-col :sm="10" :md="12">
        <template>
          <el-carousel
            trigger="click"
            height="300px"
            :autoplay="false"
            :interval="5000"
          >
            <el-carousel-item>
              <div style="display: flex; justify-content:center">
                <el-col :sm="10" :md="17">
                  <top-virtual-agent-utilizers-card />
                </el-col>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div style="display: flex; justify-content:center">
                <el-col :sm="10" :md="17"> <call-volume-card /> </el-col>
              </div>
            </el-carousel-item>
            <el-carousel-item>
              <div style="display: flex; justify-content:center">
                <el-col :sm="14" :md="17"><top-chatbot-usage-card /></el-col>
              </div>
            </el-carousel-item>
          </el-carousel>
        </template>
      </el-col>
      <el-col class="hidden-sm-and-down" :md="7">
        <announcements class="announcements-col" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <activity-log-table />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import CallVolumeCard from "@/views/dashboard/components/CallVolumeCard";
import TopVirtualAgentUtilizersCard from "@/views/dashboard/components/TopVirtualAgentUtilizersCard";
import TopChatbotUsageCard from "@/views/dashboard/components/TopChatbotUsageCard";
import Announcements from "@/views/dashboard/components/Announcements";
import ActivityLogTable from "@/views/dashboard/components/ActivityLogTable";

export default {
  name: "ServiceProviderDashboardIndex",
  components: {
    ActivityLogTable,
    Announcements,
    TopVirtualAgentUtilizersCard,
    TopChatbotUsageCard,
    CallVolumeCard,
    PageHeader
  }
};
</script>

<style lang="scss" scoped>
.page-header {
  padding-left: 0;
}

.announcements-col {
  padding-left: 40px;
}
</style>

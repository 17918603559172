<template>
  <div class="call-volume">
    <div class="call-volume-data">
      <div class="call-volume-percentage">
        {{ showPlus }}{{ animatedCallVolume }}%
      </div>
      <div class="call-volume-label">
        {{ __(":get_change call volume", { get_change: getChange }) }}
      </div>
      <div class="call-volume-label-subtext">
        {{ __("from same time last month")
        }}<template v-if="selectedAccountId === 'all'"
          >, {{ __("across all accounts") }}</template
        >
      </div>
    </div>

    <div
      v-if="can('analyse.virtual-agent-usage')"
      @click="routeToVirtualAgentUsagePage"
      class="more-info"
    >
      {{ __("More information") }}
    </div>
    <el-image
      class="dashboard-card"
      :src="callVolumeCard"
      alt="image1"
    ></el-image>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import gsap from "gsap";

export default {
  data() {
    return {
      tweenedCallVolume: 0
    };
  },
  computed: {
    ...mapState("app", {
      callVolumeSinceLastMonth: state => state.callVolumeSinceLastMonth,
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    }),

    callVolume() {
      if (this.selectedAccountId === "all") {
        return _.get(
          this.callVolumeSinceLastMonth,
          "all_sp_" + this.selectedServiceProviderId
        );
      }
      return _.get(this.callVolumeSinceLastMonth, this.selectedAccountId);
    },

    animatedCallVolume: function() {
      return !this.tweenedCallVolume
        ? parseInt("0").toFixed(0)
        : (+this.tweenedCallVolume).toFixed(0);
    },
    callVolumeCard() {
      return require(`@/assets/images/agent-utilization/call-volume.svg`);
    },
    showPlus() {
      return this.tweenedCallVolume > 0 ? "+" : "";
    },
    getChange() {
      return this.callVolume >= 0 ? __("Increased") : __("Decreased");
    }
  },
  methods: {
    routeToVirtualAgentUsagePage() {
      this.$router.push({ name: "virtual-agent-usage" }).catch(() => {});
    }
  },
  mounted() {
    this.tweenedCallVolume = this.callVolume;
  },
  watch: {
    callVolume(val) {
      gsap.to(this.$data, { duration: 0.5, tweenedCallVolume: val });
    }
  }
};
</script>

<style scoped lang="scss">
.call-volume {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: cornflowerblue;
  border-radius: 5px;
  box-shadow: 0 0 8px 3px rgba(209, 209, 209, 0.3);
  max-width: 225px;

  @media only screen and (min-width: 375px) {
    max-width: 285px;
  }

  @media only screen and (min-width: 425px) {
    max-width: 335px;
  }

  @media only screen and (min-width: 768px) {
    max-width: 350px;
  }

  @media only screen and (min-width: 992px) {
    max-width: 380px;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 500px;
  }

  @media only screen and (min-width: 1920px) {
    max-width: 600px;
  }

  .call-volume-data {
    position: absolute;
    left: 6%;
    padding-bottom: 20px;

    .call-volume-percentage {
      font-size: 1.4rem;
      font-weight: 900;

      @media only screen and (min-width: 425px) {
        font-size: 1.6rem;
      }

      @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 1.8em;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 1.9em;
      }

      @media only screen and (min-width: 1920px) {
        font-size: 2.5rem;
      }

      @media only screen and (min-width: 2560px) {
        font-size: 2.8rem;
      }
    }

    .call-volume-label {
      font-size: 1rem;
      font-weight: 700;

      @media only screen and (min-width: 425px) {
        font-size: 1.2rem;
      }

      @media only screen and (min-width: 768px) {
        font-size: 1.1rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 1.2em;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 1.3em;
      }

      @media only screen and (min-width: 1920px) {
        font-size: 1.5rem;
      }

      @media only screen and (min-width: 2560px) {
        font-size: 1.6rem;
      }
    }

    .call-volume-label-subtext {
      font-size: 0.75rem;
      line-height: 0.8;

      @media only screen and (min-width: 425px) {
        font-size: 0.8rem;
      }

      @media only screen and (min-width: 1024px) {
        font-size: 0.8125em;
      }

      @media only screen and (min-width: 1200px) {
        font-size: 0.875rem;
      }

      @media only screen and (min-width: 1920px) {
        font-size: 1rem;
      }

      @media only screen and (min-width: 2560px) {
        font-size: 1.1rem;
      }
    }
  }

  .more-info {
    position: absolute;
    bottom: 10px;
    left: 6%;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.7rem;

    &:hover {
      color: darken(cornflowerblue, 20%);
    }

    @media only screen and (min-width: 425px) {
      font-size: 0.7125rem;
      &:hover {
        font-size: 0.7225rem;
      }
    }

    @media only screen and (min-width: 768px) {
      font-size: 0.725rem;
      &:hover {
        font-size: 0.735rem;
      }
    }

    @media only screen and (min-width: 1024px) {
      font-size: 0.7625em;
      &:hover {
        font-size: 0.7725rem;
      }
    }

    @media only screen and (min-width: 1200px) {
      font-size: 0.8rem;
      &:hover {
        font-size: 0.81rem;
      }
    }

    @media only screen and (min-width: 1920px) {
      font-size: 0.8225rem;
      &:hover {
        font-size: 0.85rem;
      }
    }

    @media only screen and (min-width: 2560px) {
      font-size: 1rem;
      &:hover {
        font-size: 1.225rem;
      }
    }
  }

  .dashboard-card {
    z-index: -1;
  }
}
</style>
